export default class modalWithButtons extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  // Props
  title = "";
  bodyContent = "";
  onCancel = () => {};
  onAccept = () => {};

  connectedCallback() {
    this._template();
    this._listenEvents();
  }

  _template() {
    this.content = document.createElement("div");
    const html = `    
            <div id=content>${this.bodyContent}</div>
            <div id=footer>
                <button type="button" id="cancel" class="action-button">${gettext("Cancel")}</button>
                <button type="button" id="accept" class="action-button accept-button">${gettext("Accept")}</button>
            </div>
        `;
    this.content.innerHTML = html;
    // Insert previous content into the modal
    this.modal = document.createElement("modal-dialog");
    this.modal.extraStyles = this.contentStyles;
    this.modal.dataset.title = this.title;
    this.modal.onClose = this.onCancel;
    this.shadowRoot.appendChild(this.modal);
    this.modal.shadowRoot
      .querySelector(".modal-content")
      .appendChild(this.content);
  }

  contentStyles = `
        div {
          width: 100%;
        }
        #content {
            width: 90%;
            position: relative;
            background: white;
            padding: 1rem;
        }

        #footer {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .action-button {
            appearance: none;
            margin: 0 0.25rem;
            padding: 0 2rem;
            border: 2px solid rgb(117, 81, 233);
            height: 48px;
            border-radius: calc(12px);
            cursor: pointer;
            width: auto;
            position: relative;
            color: rgb(117, 81, 233);
            background: white;
            -webkit-appearance: none;
            font-size: 1rem;
        }

        .accept-button {
            color: white;
            background: rgb(117, 81, 233);
        }
        
        .modal-content {
            display: flex;
            margin-top:0;
        }

        @media (min-width: 600px) {
            .modal-content {
                width: 570px;
            }
        }
    `;

  _listenEvents() {
    // Add event listeners to the buttons
    // Cancel
    this.modal.shadowRoot
      .querySelector("#cancel")
      .removeEventListener("click", this._handleCancel.bind(this));
    this.modal.shadowRoot
      .querySelector("#cancel")
      .addEventListener("click", this._handleCancel.bind(this));
    // Accept
    this.modal.shadowRoot
      .querySelector("#accept")
      .removeEventListener("click", this._handleAccept.bind(this));
    this.modal.shadowRoot
      .querySelector("#accept")
      .addEventListener("click", this._handleAccept.bind(this));
  }

  _handleCancel() {
    this.onCancel();
    this.modal.destroy();
  }

  _handleAccept() {
    this.onAccept();
    this.modal.destroy();
  }
}
