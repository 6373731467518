import Icons from "../../definitions/Icons.js";
import AtomSaver from "../AtomLinksList/AtomSaver.js";

export default class QRCodeStylingComponent extends HTMLElement {
  downloadButton;

  downloadButtonContent = `${Icons.downloadCloud} ${gettext("Download QR")}`;

  constructor() {
    super();
  }

  async connectedCallback() {
    this.attachShadow({ mode: "open" });
    // Needed when QR can be personalized
    // this.atomSaver = new AtomSaver();
    this._styles();
    await this._template();
    this._listenEvents();
  }

  async _getImageBlob(src) {
    const response = await fetch(src);
    try {
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.status}`);
      }
      const blob = await response.blob();
      return blob;
    } catch (err) {
      throw new Error("Failed to fetch image");
    }
  }

  _blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async _template() {
    // const blob = await this._getImageBlob(
    //   "/static/images/logo/a-linear-square_512.png",
    // );
    // const base64 = await this._blobToBase64(blob);
    this.qrCode = new QRCodeStyling({
      width: 250,
      height: 250,
      type: "svg",
      data: `https://atom.bio/${window.userdata.user_url}`,
      // image: base64,
      dotsOptions: {
        // color: "#8B1298",
        // type: "rounded",
      },
      backgroundOptions: {
        color: "white",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
    });

    if (this.dataset.render === "true") {
      const ctn = document.createElement("div");
      ctn.classList.add("container");
      this.qrCode.append(ctn);
      this.shadowRoot.appendChild(ctn);
      this.downloadButton = document.createElement("button");
      this.downloadButton.innerHTML = this.downloadButtonContent;
      // g4a dataset, not adding location
      this.downloadButton.classList.add("ga4-track");
      this.downloadButton.dataset.ga4Type = "qr-code-download-button";
      this.downloadButton.dataset.ga4UserUrl = window.userdata.user_url;
      this.downloadButton.dataset.ga4UserAccountId = window.userdata.id;
      ctn.appendChild(this.downloadButton);
    } else {
      this._handleDownload();
    }
  }

  _styles() {
    const style = document.createElement("style");
    style.innerHTML = `
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        button {
            appearance: none;
            border: 0;
            height: 48px;
            border-radius: calc(12px);
            cursor: pointer;
            border: 2px solid rgb(117, 81, 233); 
            position: relative;
            background: white;
            color: rgb(117, 81, 233);
            -webkit-appearance: none;
            font-size: 1rem;
            font-family: 'Karla';
            margin-top: 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    `;
    this.shadowRoot.appendChild(style);
  }

  _listenEvents() {
    if (this.downloadButton) {
      this.downloadButton.addEventListener(
        "click",
        this._handleDownload.bind(this),
      );
    }
  }

  async _handleDownload(e) {
    if (e) {
      e.target.textContent = gettext("generating...");
    }
    // This is a hack to wait for the download to finish
    setTimeout(async () => {
      switch (this.dataset.action) {
        case "download":
          await this.qrCode.download({
            name: "qr",
            extension: "png",
          });
          break;
        case "show":
          const blob = await this.qrCode.getRawData();
          const objectURL = URL.createObjectURL(blob);
          document.location = objectURL;
      }

      if (this.downloadButton) {
        this.downloadButton.innerHTML = this.downloadButtonContent;
      }
    });
  }
}
